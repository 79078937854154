import Vue from "vue";
import VueRouter from "vue-router";
import App from "../App.vue";


Vue.use(VueRouter);

const routes = [{
	path: "/",
	name: "App",
	component: App,
	children: [
		{
			path: "/login",
			name: "login",
			component: () => import("../views/login.vue")
		},
		{
			path: "/",
			name: "home",
			component: () => import("../views/home.vue")
		}
	]
}];

const router = new VueRouter({
	mode: 'history',
	routes
});

export default router;

import Vue from 'vue'
import Vuex from "vuex";
import App from './App.vue'
import vuetify from './plugins/vuetify'
import myHttp from './utils/myHttp'
import Toasted from "vue-toasted"
import router from "./router"
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import Vuetify from "vuetify/lib";
import "vuetify/dist/vuetify.min.css";
import VuetifyConfirm from "vuetify-confirm";
import Vuelidate from 'vuelidate'

Vue.config.productionTip = false
Vue.prototype.$myHttp = myHttp;

Vue.use(Toasted);
Vue.use(ElementUI);
Vue.use(Vuex);

Vue.use(Vuetify);
Vue.use(VuetifyConfirm, { vuetify });
Vue.use(Vuelidate)



new Vue({
	el: "#app",
	router,
	store,
	vuetify,
	render: h => h(App)
}).$mount('#app')

// src/plugins/vuetify.js

import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import ja from '@/locale/ja';    // 导入日语语言包
import en from '@/locale/en';    // 导入English语言包

Vue.use(Vuetify)

export default new Vuetify({
	icons: {
		iconfont: 'mdi',
	},
	lang: {
		locales: { ja, en },
		current: 'ja',
	},
	theme: {
		themes: {
			light: {
				primary: '#1976D2',
				secondary: '#424242',
				accent: '#82B1FF',
				error: '#FF5252',
				info: '#2196F3',
				success: '#4CAF50',
				warning: '#FF6B00',
			},
		},
	},
})


// import Vue from 'vue';
// // import Vuetify from 'vuetify/lib/framework';
// import ja from '@/locale/ja';    // 导入日语语言包
// import en from '@/locale/en';    // 导入English语言包

// Vue.use(Vuetify);

// export default new Vuetify({
// 	lang: {
// 		locales: { ja, en },
// 		current: 'ja',
// 	}
// });
import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from "vuex-persist";
Vue.use(Vuex);

const vuexLocal = new VuexPersist({
	key: 'vuex',
	storage: window.localStorage,
});
export default new Vuex.Store({
	state: {
		token: '',
	},
	mutations: {
		updateToken(state, token) {
			this.state.token = token;
		},
	},
	actions: {},
	modules: {},
	plugins: [vuexLocal.plugin],
});

// src/locale/en.js
import en from 'vuetify/es5/locale/en';


export default {
	label: 'English',
	//page login
	login: 'Login',
	logout: 'Logout',
	username: 'Username',
	password: 'Password',
	// menu
	back: 'Back',
	submit: 'Submit',
	del: 'Delete',
	upload: 'Upload',
	search: 'Search',
	title: 'Title',
	operate: 'Operate',
	name: 'Name',
	namekatakana: 'NameKatakana',
	type: 'Type',
	content: 'Content',
	del: 'Delete',
	edit: 'Edit',
	create: 'Create',
	productlist: 'Product List',
	image: 'Image',
	price: 'Price(JPY)',
	capacity: 'Capacity(pcs)',
	description: 'Description',
	tagline: 'Tagline',
	tagline_detail: 'Tagline_detail',
	ingredient: 'Ingredient',
	recommend: 'Recommend',
	dosage: 'Dosage',

	msg: 'Item Text',
	//
	...en,
}
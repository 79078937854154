import ja from 'vuetify/es5/locale/ja';  // 导入Vuetify 自带的简体中文语言包

export default {
	label: '日本語',
	// page login
	login: '登录',
	logout: 'ログアウト',
	username: '用户名',
	password: '密码',
	// menu
	back: '戻る',
	submit: '送る',
	del: '削除する',
	upload: 'アップロード',
	search: '検索',
	title: 'タイトル',
	operate: '操作',
	name: '商品名',
	namekatakana: 'カタカナ',
	type: 'タイプ',
	content: '内容',
	del: '削除',
	edit: '編集',
	create: '新規',
	productlist: '製品リスト',
	image: '写真',
	price: '価格（円）',
	capacity: '内容量（粒）',
	description: '説明',
	tagline: 'プロモーションタイトル',
	tagline_detail: '説明Ⅱ',
	ingredient: '主要成分',
	recommend: 'お摂りいただきたい方',
	dosage: '召し上がり方',

	msg: 'Item Text',

	...ja,
}
<template>
	<v-app class="fill-height">
		<router-view />
	</v-app>
</template>

<script>
export default {
	name: 'App',

	data: () => ({
		//
	}),
};
</script>

<style scoped>
@import './assets/styles/index.css';
</style>